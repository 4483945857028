<template>
	<form @submit.prevent="sendForm(true)" class="form__ask-expert" ref="form" v-if="!getAuthStatus">
		<fieldset class="form__ask-expert--wrapper" >
			<fieldset class="form-row">
				<div class="input-wrapper">
					<label for="email" class="label__input required">Адрес электронной почты</label>
					<input type="email" v-model="user_data.email" name="email" id="email" required class="input" placeholder="ivanov@organization.com">
					<span class="validity-icon"></span>
				</div>
				<div class="input-wrapper">
					<label for="password" class="label__input required">Пароль</label>
					<input type="password" v-model="user_data.password" name="password" id="password" required class="input">
					<span class="validity-icon"></span>
				</div>
				<p class="error-msg" v-if="getLoginStatusCode == 403"><strong>Неверный логин или пароль</strong></p>
				<p class="error-msg" v-if="getLoginStatusCode == 404"><strong>Пользователя с таким email не существует</strong></p>
			</fieldset>
			<fieldset class="form-row__submit">
				<button type="submit" class="btn__blue">Войти</button>
			</fieldset>
		</fieldset>
	</form>
	<form @submit.prevent="sendForm(false)" class="form__ask-expert" ref="form" v-else-if="getAuthStatus">
		<fieldset class="form__ask-expert--wrapper" >
			<fieldset class="form-row__submit">
				<button type="submit" class="btn__blue">Выйти</button>
			</fieldset>
		</fieldset>
	</form>
</template>

<script>
import AuthStatus from '@/helpers/auth.js'
import { mapActions, mapGetters } from 'vuex'

export default {
	data() {
		return {
			user_data: {
				email: null,
				password: null
			},
		}
	},
	computed: {
		...mapGetters(['getLoginStatusCode', 'getAuthStatus']),
		isAuthenticated() {
			return AuthStatus.isAuthenticated()
		},
		userRole() {
			return AuthStatus.getRole()
		},
	},
	methods: {
		...mapActions(['cleanLoginStatusCode', 'login', 'logout']),
		sendForm(bool) {
			if(!bool) {
				this.logout()
			} else {
				this.login(this.user_data)
			}
		},
		restartPage() {
			for(let data in this.user_data) {
				this.user_data[data] = null
			}
			this.cleanLoginStatusCode()
		}
	},
	watch: {
		getAuthStatus(value) {
			console.log(value)
			if(value) {
				if(this.userRole === '1') this.$router.push({ name: 'AdminPage' })
				if(this.userRole === '2') this.$router.push({ name: 'OrganizationRegistry' })
			} else {
				this.$router.push({ name: 'Login' })
			}
		}
	},
	beforeDestroy() {
		this.cleanLoginStatusCode()
	}
}
</script>

<style scoped>
	.form__ask-expert {
		max-width: 500px;
	}
	.error-msg {
		color: tomato;
		margin: 0; padding: 0;
	}
</style>
