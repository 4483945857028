<template>
	<main class="vcc-main">
		<h1 class="heading">Вход</h1>
		<login-form />
	</main>
</template>

<script>
import LoginForm from '../components/forms/LoginForm.vue'
export default {
  components: { LoginForm },

}
</script>

<style>

</style>
